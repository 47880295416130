import request from "../api/requests.ts";
import { tg } from '../App.js'


export const addUser = async () => {
    console.log(tg.initDataUnsafe);
    const data = {
        userId: tg.initDataUnsafe.user.id,
        username: tg.initDataUnsafe.user.username,
        firstName: tg.initDataUnsafe.user.first_name,
        lastName: tg.initDataUnsafe.user.last_name,
    }
    await request("POST", "add_user", data);
}

export const checkStartParam = async (navigate) => {
    const startParam = tg.initDataUnsafe.start_param;
    if (startParam) {
        if (startParam === 'gifts' || startParam === 'profile') {
            navigate(`/${startParam}`);
        } else if (startParam.startsWith('view')) {
            const token = startParam.split('-')[1];
            navigate(`/profile`, { state: { token } });
        } else {
            const data = {
                'userId': tg.initDataUnsafe.user.id,
                'inlineToken': startParam
            }
            const response = await request("POST", "receive_gift", data);
            if (response.data) {
                const data = response.data;
                if (data.isReceived) {
                    alert("Подарок уже был получен!");
                    navigate('/store');
                }
                if (data.isNotValidToken) {
                    alert("Не найден такой подарок!");
                    navigate('/store');
                }
                if (response.data.action) {
                    const giftId = response.data.gift.id;
                    const giftName = response.data.gift.name;
                    const userFromFirstName = response.data.userFromFirstName;
                    document.querySelector('.tabbar').style.display = 'none';
                    navigate('/giftreceived', { state: {giftId, giftName, userFromFirstName, startParam} });
                }
            } else {
                tg.showPopup({ message: "Произошла ошибка!" });
                navigate('/store');
            }
        }
    } else {
        navigate('/store');
        // const token = 'IViKHokHEWJe';
        // navigate(`/profile`, { state: { token } });
    }
}

// export const getUserRank = async (userId: string) => {
//     try {
//         const response = await request("GET", "userrank", { userId: userId });
//         return response.data;
//     } catch (error) {
//         console.error("Ошибка при получении места пользователя:", error);
//         return { error: "Не удалось получить данные о пользователе" };
//     }
// }