import './style.css';
import React, {useEffect, useState} from "react";
import {tg} from '../../App.js'
import request from "../../api/requests.ts";
import {useTranslation} from 'react-i18next';
import HistoryItem from './HistoryItem.tsx';
import {useNavigate} from "react-router-dom";
import {useLanguage} from '../../hooks/use-theme.ts';
import Loader from '../shared/Loader.tsx';
import Empty from '../shared/Empty.tsx';


const History = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { lang } = useLanguage();

    const [loading, setLoading] = useState(true);

    const userId = tg.initDataUnsafe.user.id;

    const goToProfile = () => {
        navigate('/profile');
        tg.BackButton.hide();
    }

    useEffect(() => {
        tg.BackButton.show();
        tg.BackButton.onClick(goToProfile);

        return () => {
            tg.BackButton.offClick(goToProfile);
            tg.BackButton.hide();
        };
    }, []);

    const [actions, setActions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [fetching, setFetching] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        if (fetching) {
            console.log("fetching");
            const fetchGifts = async () => {
                try{
                    const response = await request("GET", "userhistory", {'userId': userId, 'limit': 20, 'page': currentPage});
                    const data = await response.data.actions;
                    const totalRecords = await response.data.totalRecords;
                    setActions([...actions, ...data]);
                    setCurrentPage(prevState => prevState + 1);
                    setTotalCount(Number(totalRecords));
                } catch (e) {
                    console.log("error", e);
                } finally {
                    setFetching(false);
                    setLoading(false);
                }
            }

            fetchGifts()
        }
    }, [fetching]);



    useEffect(() => {
        const rootElement = document.getElementById('root');
        rootElement.addEventListener('scroll', scrollHandler);

        return function () {
            rootElement.removeEventListener('scroll', scrollHandler);
        };
    }, [currentPage, actions, totalCount]);

    const scrollHandler = (e) => {
        const rootElement = e.target;
        if (rootElement.scrollHeight - (rootElement.scrollTop + window.innerHeight) < 100 && actions.length < totalCount) {
            setFetching(true);
        }
    };

    if (loading) {
        return <Loader />;
    }

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        if (lang === 'en') {
            const day = date.getDate();
            const month = date.toLocaleString(lang, { month: 'long' });
            return `${day} ${month}`;
        }
        return date.toLocaleDateString(lang, { day: 'numeric', month: 'long' });
    };

    const groupedEvents = actions.reduce((acc, event) => {
        const date = event.update_at.split('T')[0];
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(event);
        return acc;
    }, {});

    return (
        <div className="history">
            <header>
                <p className="title-1">{t("profile.history.title1")}</p>
                <p className="title-2">{t("profile.history.title2")}</p>
            </header>
            
            {actions.length === 0 ? (
                <Empty page="profile" />
            ) : (
                Object.keys(groupedEvents).map((date) => (
                    <div key={date}>
                        <div className="history-date"><p>{formatDate(date)}</p></div>
                        {groupedEvents[date].map((event, index, eventsArray) => {
                            const isLastItem = index === eventsArray.length - 1;
                            return (
                                <HistoryItem
                                    key={event._id}
                                    userId={userId}
                                    isLastItem={isLastItem}
                                    {...event} />
                            );
                        })}
                    </div>
                ))
            )}
        </div>
    )
};

export default History;



