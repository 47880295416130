import './style.css';
import React from "react";
import Lottie from "lottie-react";
import animationEmojiBalloons from "../../assets/animations/emoji-balloons.json";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


const Empty = ({ page }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goToStore = () => {
        navigate('/store');
    }

    return (
        <div className="empty">
            <Lottie animationData={animationEmojiBalloons} loop={false} autoplay={true} style={{width: "100px", height: "100px"}}/>
            <p className="text">
                {page === "gifts" ? t("gifts.empty") : t("profile.empty")}
            </p>
            <p className="link" onClick={goToStore}>{t("button.open_store")}</p>
        </div>
    )
};

export default Empty;