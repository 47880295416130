import Lottie from "lottie-react";
import animationBlueStar from "./animations/gift-blue-star.json";
import animationDeliciousCake from "./animations/gift-delicious-cake.json";
import animationGreenStar from "./animations/gift-green-star.json";
import animationRedStar from "./animations/gift-red-star.json";


const defaultProps = {
    loop: false,
    autoplay: true
};

export const animationGiftIcons = {
    1: <Lottie animationData={animationDeliciousCake} {...defaultProps} />,
    2: <Lottie animationData={animationGreenStar} {...defaultProps} />,
    3: <Lottie animationData={animationBlueStar} {...defaultProps} />,
    4: <Lottie animationData={animationRedStar} {...defaultProps} />,
}


