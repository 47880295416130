import './style.css';
import React, {useEffect, useState} from "react";
import {tg} from '../../App.js'
import { useTheme, useLanguage } from "../../hooks/use-theme.ts";
import Empty from "../shared/Empty.tsx";
import {themeIcons} from '../../assets/themeIcons.tsx'
import request from "../../api/requests.ts";
import {ReceivedGiftCard} from '../shared/ReceivedGiftCard.tsx';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from "react-router-dom";
import Loader from "../shared/Loader.tsx";
import Modal from "../shared/Modal.tsx";
import premiumIcon from "../../assets/premiumIcon.svg";


const Profile = () => {
    const location = useLocation();
    const startParam = location.state?.token;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [modalActive, setModalActive] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const { theme, setTheme } = useTheme();
    const { lang, setLanguage } = useLanguage();

    const switchLanguage = () => {
        setLanguage((lang) => (lang === "en" ? "ru" : "en"));
    };

    const switchTheme = () => {
        setTheme((theme) => (theme === "dark" ? "light" : "dark"));
    }

    const goToHistory = () => {
        navigate('/history');
    }

    const userId = tg.initDataUnsafe.user.id;
    const userPhoto = `${process.env.REACT_APP_SERVER_URL}/api/user_photos/${userId}`;


    const [gifts, setGifts] = useState([]);
    const [user, setUser] = useState();
    const [rank, setRank] = useState();

    useEffect(() => {
        const fetchGifts = async () => {
            try {
                let response = await request("GET", "myreceivedgifts", {'userId': userId});
                const giftData = await response.data;
                response = await request("GET", "myinfo", {'userId': userId});
                const userData = await response.data;
                response = await request("GET", "userrank", {'userId': userId});
                const userRank = await response.data;
                setGifts(giftData);
                setUser(userData);
                setRank(userRank);

                if (startParam) {
                    response = await request("GET", "receivedgift", {'token': startParam});
                    const modalContent = await response.data;
                    setModalContent(modalContent[0]);
                    setModalActive(true);
                }
            } catch (error) {
                console.error("Ошибка при загрузке подарков:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchGifts();
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="profile">
            <header>
                <div className="info">
                    <div className="photo">
                        {/*<img src={img} alt="User Photo"/>*/}
                        <img src={userPhoto} alt={"User Photo"}/>
                        <p>#{rank}</p>
                    </div>
                    <div className="details">
                        <div className="name">
                            <p>{user?.first_name || 'Anonymous'}</p>
                            {user?.is_premium && <img alt="Is Premium" src={premiumIcon}/>}
                        </div>
                        <p>{user?.gifts_received || 0} {t("profile.gifts_received")}</p>
                    </div>
                </div>
                <div className="recent-actions" onClick={goToHistory}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 16C3.58431 16 0 12.4157 0 8C0 3.58431 3.58431 0 8 0C12.4157 0 16 3.58431 16 8C16 12.4157 12.4157 16 8 16ZM8 14.6667C11.6863 14.6667 14.6667 11.6863 14.6667 8C14.6667 4.31373 11.6863 1.33333 8 1.33333C4.31373 1.33333 1.33333 4.31373 1.33333 8C1.33333 11.6863 4.31373 14.6667 8 14.6667ZM3.89804 8.84706C3.58431 8.84706 3.34902 8.61176 3.34902 8.29804C3.34902 7.99216 3.58431 7.75686 3.89804 7.75686H7.45098V3.01176C7.45098 2.70588 7.68627 2.47059 7.99216 2.47059C8.29804 2.47059 8.54118 2.70588 8.54118 3.01176V8.29804C8.54118 8.61176 8.29804 8.84706 7.99216 8.84706H3.89804Z"
                            fill="#007AFF"/>
                    </svg>
                    <p>{t("profile.recent_actions")}</p>
                </div>
            </header>
            {gifts.length > 0 ? (
                <div className="content">
                    {gifts.map((gift) => (
                        <ReceivedGiftCard
                            key={gift._id}
                            modalActive={modalActive}
                            setModalActive={setModalActive}
                            setModalContent={setModalContent}
                            {...gift}
                        />
                    ))}
                </div>
            ) : (
                <div className="no-content">
                    <Empty page="profile" />
                </div>
            )}


            <div className="switch">
                <div className="segment" onClick={switchTheme}>
                    <div className={`button ${theme === "light" ? "active" : ""}`}>
                        {themeIcons['light']}
                    </div>
                    <div className={`button ${theme === "dark" ? "active" : ""}`}>
                        {themeIcons['dark']}
                    </div>
                </div>
                <div className="segment" onClick={switchLanguage}>
                    <div className={`button ${lang === "en" ? "active" : ""}`}>
                        EN
                    </div>
                    <div className={`button ${lang === "ru" ? "active" : ""}`}>
                        RU
                    </div>
                </div>
            </div>

            <Modal
                active={modalActive}
                setActive={setModalActive}
                props={modalContent}
                callPage={'profile'}
            />
        </div>
    )
};

export default Profile;