import "./style.css";
import React from "react";
import {useTranslation} from "react-i18next";
import {animationGiftIcons} from "../../assets/giftAnimatedIcons.tsx";


export const GiftPurchased = (props) => {
    const { t } = useTranslation();

    const openModal = () => {
        if (!props.modalActive) {
            props.setModalContent(props)
            props.setModalActive(true)
        }
    };

    return (
        <div className='giftPurchasedCard'>
            <span className='name'>{props.gift_details.name}</span>
            <div className="gift-image">{animationGiftIcons[props.gift_details.id]}</div>
            <button className='send-button' onClick={openModal} disabled={props.modalActive}>
                <span className='send'>{t("button.send")}</span>
            </button>
        </div>
    );
};

