import "./style.css";
import React from "react";
import {useTranslation} from "react-i18next";
import {animationGiftIcons} from "../../assets/giftAnimatedIcons.tsx";


export const ReceivedGiftCard = (props) => {
    const { t } = useTranslation();

    const openModal = () => {
        if (!props.modalActive) {
            props.setModalContent(props)
            props.setModalActive(true)
        }
    };

    const userPhoto = `${process.env.REACT_APP_SERVER_URL}/api/user_photos/${props.user_id_from}`;

    return (
        <div className="giftReceivedCard" onClick={openModal}>
            <div className="giftInfo">
                <img src={userPhoto} alt="" className="giftIcon"/>
                <span>1 {t("of")} 10K</span>
            </div>
            <div className="giftImage">{animationGiftIcons[props.gift_details.id]}</div>
            <h2 className="giftTitle">{props.gift_details.name}</h2>
        </div>
    );
};

