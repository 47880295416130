import './style.css';
import { tg } from "../../App.js"
import { useEffect } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { giftIcon } from '../../assets/gifts/icons.tsx';
import Lottie from "lottie-react";
import animationGiftPurchased from "../../assets/animations/effect-gift-purchased.json";
import { animationGiftIcons } from '../../assets/giftAnimatedIcons.tsx';
import {useTranslation, Trans} from "react-i18next";


const GiftPurchased = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { id, name, price, currency, hash } = location.state;
    const navigate = useNavigate();
    // const { id, name, price, currency, hash } = { id: 1, name: 'Delicious Cake', price: 10, currency: 'USDT', hash: 'IVsrRc4qdQpy' };

    const goToStore = () => {
        navigate('/store');
        document.querySelector('.tabbar').style.display = 'block';
        tg.BackButton.hide();
        tg.MainButton.hide();
        tg.SecondaryButton.hide();
    }

    const sendGift = () => {
        tg.switchInlineQuery(hash, ['users']);
    };

    useEffect(() => {
        console.log({id, name, price, currency, hash});

        tg.BackButton.show();
        tg.BackButton.onClick(goToStore);

        tg.MainButton.show();
        tg.MainButton.setText(t("button.send_gift"));
        tg.MainButton.onClick(sendGift);

        tg.SecondaryButton.show();
        tg.SecondaryButton.setText(t("button.open_store"));
        tg.SecondaryButton.setParams({position: "bottom"});
        tg.SecondaryButton.onClick(goToStore);

        return () => {
            tg.BackButton.offClick(goToStore);
            tg.SecondaryButton.offClick(goToStore);
            tg.MainButton.offClick(sendGift);
        };
    }, []);

    return (
        <div className="placeholder">
            <div className="gift-animatation">
                {animationGiftIcons[id]}
            </div>
            <div className="animat">
                <Lottie animationData={animationGiftPurchased} loop={false} autoplay={true} style={{width: "350px", height: "350px"}}/>
            </div>
            <div className="text-wrapper">{t("store.gift_purchased.title")}</div>
            <p className="text-info">
                <Trans i18nKey="store.gift_purchased.info"
                    values={{ name: name, price: price, currency: currency }}
                    components={{ b: <b /> }}/>
            </p>
            <div className="notification">
                <div className="animation">{animationGiftIcons[id]}</div>
                <div className="text">
                    <p><b>{t("store.notification.top")}</b></p>
                    <p>{t("store.notification.bottom")}</p>
                </div>
                <div className="send" onClick={sendGift}>Send</div>
            </div>
        </div>
    )
};


export default GiftPurchased;