import './style.css';
import React, {useEffect, useState} from "react";
import {tg} from '../../App.js'
import Empty from "../shared/Empty.tsx";
import request from "../../api/requests.ts";
import {ReceivedGiftCard} from '../shared/ReceivedGiftCard.tsx';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from "react-router-dom";
import Loader from "../shared/Loader.tsx";
import Modal from "../shared/Modal.tsx";
import premiumIcon from '../../assets/premiumIcon.svg'


const LeaderboardProfile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [gifts, setGifts] = useState([]);
    const [user, setUser] = useState();
    const [modalActive, setModalActive] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const { userId, rank, backPage } = location.state;

    const userPhoto = `${process.env.REACT_APP_SERVER_URL}/api/user_photos/${userId}`;

    const goToBack = () => {
        navigate(`/${backPage}`);
        tg.BackButton.hide();
    }

    useEffect(() => {
        const fetchGifts = async () => {
            try {
                let response = await request("GET", "myreceivedgifts", {'userId': userId,});
                const giftData = await response.data;
                response = await request("GET", "myinfo", {'userId': userId,});
                const userData = await response.data;
                setGifts(giftData);
                setUser(userData);
            } catch (error) {
                console.error("Ошибка при загрузке подарков:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchGifts();

        tg.BackButton.show();
        tg.BackButton.onClick(goToBack);

        return () => {
            tg.BackButton.offClick(goToBack);
            tg.BackButton.hide();
        };
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="leaderboard-profile">
            <header>
                <div className="info">
                    <div className="photo">
                        <img src={userPhoto} alt={"User Photo"}/>
                        <p>#{rank}</p>
                    </div>
                    <div className="details">
                        <div className="name">
                            <p>{user?.first_name || 'Anonymous'}</p>
                            {user?.is_premium && <img alt="Is Premium" src={premiumIcon}/>}
                        </div>
                        <p>{user?.gifts_received || 0} {t("profile.gifts_received")}</p>
                    </div>
                </div>
            </header>
            {gifts.length > 0 ? (
                <div className="content">
                    {gifts.map((gift) => (
                        <ReceivedGiftCard
                            key={gift._id}
                            modalActive={modalActive}
                            setModalActive={setModalActive}
                            setModalContent={setModalContent}
                            {...gift}
                        />
                    ))}
                </div>
            ) : (
                <div className="no-content">
                    <Empty page="profile" />
                </div>
            )}

            <Modal
                active={modalActive}
                setActive={setModalActive}
                props={modalContent}
                callPage={'profile'}
            />
        </div>
    )
};

export default LeaderboardProfile;