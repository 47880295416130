import React from 'react';
import './style.css';
import {useTranslation} from 'react-i18next';
import {actionTypes} from '../../assets/actionTypes.tsx';
import {animationGiftIcons} from '../../assets/giftAnimatedIcons.tsx';
import {useNavigate} from "react-router-dom";
import request from "../../api/requests.ts";


const getTypeAction = (props, t) => {
    if (props.status === 'sent' || props.status === 'purchase') {
        return ["Buy", t("profile.history.buy")]
    }
    if (props.userId == props.userFromDetails.id) {
        return ["Sent", t("profile.history.sent")]
    }
    return ["Receive", t("profile.history.receive")]
}



const HistoryItem = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const type = getTypeAction(props, t);

    const actionColor = {
        Buy: "#007AFF",
        Sent: "#AF51DE",
        Receive: "#35C759"
    };

    const getUserRank = async (userId: string) => {
        try {
            const response = await request("GET", "userrank", {'userId': userId});
            return await response.data;
        } catch (error) {
            console.error("Ошибка при загрузке подарков:", error);
        }
    };

    const goToProfile = async (userId: string) => {
        const rank = await getUserRank(userId);
        const backPage = "history";
        navigate('/leaderboardprofile', { state: {userId, rank, backPage} });
    }

    return (
        <div className="history-item">
            <div className="history-icon">
                <div className="image">
                    <div className="gift-image">{animationGiftIcons[props.giftDetails.id]}</div>
                </div>
                <div className="icon" style={{background: actionColor[type[0]]}}>{actionTypes[type[0]]}</div>
            </div>
            <div className="history-body">
                <p className="subtitle">{type[1]}</p>
                <p className="title">{props.giftDetails.name}</p>
            </div>
            <div className="history-value">
                <p>
                    {type[0] === 'Receive' ? (
                        <span>
                            <span>{t("profile.history.from")}</span>
                            <span style={{color: '#007AFF'}} onClick={() => goToProfile(props.userFromDetails.id)}>{props.userFromDetails.first_name}</span>
                        </span>
                    ) : type[0] === 'Sent' ? (
                        <span>
                            <span>{t("profile.history.to")}</span>
                            <span style={{color: '#007AFF'}} onClick={() => goToProfile(props.userToDetails.id)}>{props.userToDetails.first_name}</span>
                        </span>
                    ) : (
                        `-${props.giftDetails.price} ${props.giftDetails.currency}`
                    )}
                </p>
            </div>
            {!props.isLastItem && <div className="separator" />}
        </div>
    )
};

export default HistoryItem;