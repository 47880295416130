import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {tg} from '../../App.js';
import {useTranslation} from "react-i18next";
import {animationGiftIcons} from "../../assets/giftAnimatedIcons.tsx";
import {currencyWithBgIcons} from "../../assets/currency/currencyWithBg.tsx";
import request from "../../api/requests.ts";


const formatDate = (dateStr, t) => {
    const date = new Date(dateStr);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${t("at")} ${hours}:${minutes}`;
};


const Modal = ({active, setActive, props, callPage}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const sendGift = () => {
        tg.switchInlineQuery(props.inline_token, ['users']);
    };

    const closeModal = () => {
        setActive(false);
    };

    useEffect(() => {
        const handleClick = callPage === 'gifts' ? sendGift : closeModal;

        if (active) {
            tg.MainButton.show();
            tg.MainButton.setText(callPage === 'gifts' ? 'Send Gift to Contact' : 'Close');
            tg.MainButton.onClick(handleClick);
            document.querySelector('.tabbar').style.display = 'none';
        } else {
            tg.MainButton.hide();
            tg.MainButton.offClick(handleClick);
            document.querySelector('.tabbar').style.display = 'block';
        }

        return () => {
            tg.MainButton.offClick(handleClick);
            tg.MainButton.hide();
        };
    }, [active]);

    const getUserRank = async (userId: string) => {
        try {
            const response = await request("GET", "userrank", {'userId': userId});
            return await response.data;
        } catch (error) {
            console.error("Ошибка при загрузке подарков:", error);
        }
    };

    const goToProfile = async (userId: string) => {
        if (userId != tg.initDataUnsafe.user.id) {
            const rank = await getUserRank(userId);
            const backPage = "profile";
            navigate('/leaderboardprofile', { state: {userId, rank, backPage} });
        }
    }

    return (
        <div className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                {props && (
                    <>
                        <div className="modal-header">
                            <div className="modal-image">{animationGiftIcons[props.gift_details.id]}</div>
                            <div className="modal-close" onClick={() => setActive(false)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="16.9497" y="5.63599" width="2" height="16" rx="1"
                                          transform="rotate(45 16.9497 5.63599)" fill="#9E9EA1"/>
                                    <rect x="18.3638" y="16.9497" width="2" height="16" rx="1"
                                          transform="rotate(135 18.3638 16.9497)" fill="#9E9EA1"/>
                                </svg>
                            </div>
                        </div>
                        <div className="modal-title">
                            <p>{callPage === 'gifts' ? 'Send Gift' : props.gift_details.name}</p>
                        </div>
                        <div className="modal-description">
                            <div className="modal-line modal-bottom-sep">
                                <div className="modal-var"><p>{callPage === 'gifts' ? t("shared.modal.gift") : t("shared.modal.from")}</p></div>
                                <div className="modal-value">
                                    {callPage === 'gifts' ? (
                                        <p>{props.gift_details.name}</p>
                                    ) : (
                                        <div className="modal-user-from" onClick={() => goToProfile(props.user_id_from)}>
                                            <img src={`${process.env.REACT_APP_SERVER_URL}/api/user_photos/${props.user_id_from}`} alt="User From Photo"/>
                                            <p>{props.user_from_details.first_name}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="modal-line modal-bottom-sep">
                                <div className="modal-var"><p>{t("shared.modal.date")}</p></div>
                                <div className="modal-value"><p>{formatDate(props.update_at, t)}</p></div>
                            </div>
                            <div className="modal-line modal-bottom-sep">
                                <div className="modal-var"><p>{t("shared.modal.price")}</p></div>
                                <div className="modal-value">
                                    {currencyWithBgIcons[props.gift_details.currency]}
                                    <p>{props.gift_details.price} {props.gift_details.currency}</p>
                                </div>
                            </div>
                            <div className="modal-line">
                                <div className="modal-var"><p>{t("shared.modal.availability")}</p></div>
                                <div className="modal-value"><p>1 {t("of")} 10 000</p></div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Modal;
