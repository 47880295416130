import React from "react";
import {Route, Routes} from "react-router-dom";
import Layout from "./Layout.tsx";
import Store from "../components/store/Store.tsx";
import GiftPage from "../components/gifts/index.tsx";
import Leaderboard from "../components/leaderboard/Leaderboard.tsx";
import Profile from "../components/profile/Profile.tsx";
import GiftBuy from "../components/store/GiftBuy.tsx";
import GiftPurchased from '../components/store/GiftPurchased.tsx';
import GiftReceived from '../components/gifts/GiftReceived.tsx';
import LeaderboardProfile from '../components/leaderboard/Profile.tsx';
import History from '../components/profile/History.tsx';



 const MainRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route path="store" element={<Store/>}/>
                <Route path="gifts" element={<GiftPage/>}/>
                <Route path="leaderboard" element={<Leaderboard />}/>
                <Route path="profile" element={<Profile/>}/>

                <Route path="giftbuy" element={<GiftBuy/>}/>
                <Route path="giftpurchased" element={<GiftPurchased/>}/>
                <Route path="giftreceived" element={<GiftReceived/>}/>
                <Route path="leaderboardprofile" element={<LeaderboardProfile/>}/>
                <Route path="history" element={<History/>}/>
            </Route>
        </Routes>
    )
}

export default MainRouter