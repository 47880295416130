import './style.css';
import React, { useEffect } from "react";
import { tg } from "../../App.js"
import {useLocation, useNavigate} from "react-router-dom";
import { giftIcon } from '../../assets/gifts/icons.tsx';
import Lottie from "lottie-react";
import animationGiftPurchased from "../../assets/animations/effect-gift-purchased.json";
import { animationGiftIcons } from '../../assets/giftAnimatedIcons.tsx';
import {useTranslation, Trans} from "react-i18next";


const GiftReceived = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { giftId, giftName, userFromFirstName, startParam } = location.state;
    const navigate = useNavigate();
    // const { id, name, firstNameFrom } = { id: 1, name: 'Delicious Cake', firstNameFrom: 'Mark' };

    const goToProfile = () => {
        navigate(`/profile`);
        document.querySelector('.tabbar').style.display = 'block';
        tg.MainButton.hide();
    }

    const viewGift = () => {
        const token = startParam;
        navigate(`/profile`, { state: { token } });
        // alert("авот") // TODO сделать открытие подарка в профиле на "Profile / Open Gift"
    }

    useEffect(() => {
        tg.MainButton.show();
        tg.MainButton.setText(t("button.open_profile"));
        tg.MainButton.onClick(goToProfile);

        return () => {
            tg.MainButton.offClick(goToProfile);
        };
    }, []);

    return (
        <div className="placeholder">
            <div className="gift-animatation">
                {animationGiftIcons[giftId]}
            </div>
            <div className="animat">
                <Lottie animationData={animationGiftPurchased} loop={false} autoplay={true} style={{width: "350px", height: "350px"}}/>
            </div>
            <div className="text-wrapper">{t("gifts.gift_received.title")}</div>
            <p className="text-info">
                <Trans i18nKey="gifts.gift_received.info"
                    values={{ name: giftName }}
                    components={{ b: <b /> }}/>
            </p>
            <div className="notification">
                <div className="animation">{animationGiftIcons[giftId]}</div>
                <div className="text">
                    <p><b>{t("gifts.notification.top")}</b></p>
                    <p>{t("gifts.notification.bottom", { name: giftName, firstNameFrom: userFromFirstName })}</p>
                </div>
                <div className="view" onClick={viewGift}>View</div>
            </div>
        </div>
    )
};


export default GiftReceived;