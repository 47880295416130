



export const actionTypes = {
    Buy: (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.94448 3.19567L9.09916 1.22461C8.97383 0.932368 8.91116 0.786233 8.81022 0.678968C8.72101 0.584164 8.61083 0.511556 8.48849 0.466961C8.35007 0.416504 8.19098 0.416504 7.8728 0.416504H2.12726C1.80908 0.416504 1.65 0.416504 1.51157 0.466961C1.38923 0.511556 1.27905 0.584164 1.18984 0.678968C1.0889 0.786234 1.02623 0.93236 0.900897 1.22461L0.05558 3.19567H9.94448Z"
                fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.830514 5.63342C1.05798 5.71682 1.30374 5.76234 1.56017 5.76234C1.98257 5.76234 2.37641 5.63844 2.70679 5.42556C3.03717 5.63844 3.43101 5.76234 3.85341 5.76234C4.2758 5.76234 4.66964 5.63844 5.00003 5.42556C5.33041 5.63844 5.72424 5.76234 6.14664 5.76234C6.56904 5.76234 6.96288 5.63844 7.29326 5.42556C7.62364 5.63844 8.01748 5.76234 8.43988 5.76234C8.69631 5.76234 8.94208 5.71681 9.16955 5.63341V8.24984C9.16955 8.71655 9.16955 8.9499 9.07866 9.12816C8.99871 9.28496 8.87114 9.41245 8.71423 9.49234C8.53585 9.58317 8.30233 9.58317 7.8353 9.58317H2.16476C1.69773 9.58317 1.46421 9.58317 1.28583 9.49234C1.12892 9.41245 1.00135 9.28496 0.921404 9.12816C0.830514 8.9499 0.830514 8.71655 0.830514 8.24984V5.63342ZM1.6644 7.33317C1.6644 7.09982 1.6644 6.98314 1.70984 6.89401C1.74982 6.81561 1.8136 6.75187 1.89206 6.71192C1.98125 6.6665 2.098 6.6665 2.33152 6.6665H3.91594C4.14945 6.6665 4.26621 6.6665 4.3554 6.71192C4.43385 6.75187 4.49764 6.81561 4.53761 6.89401C4.58306 6.98314 4.58306 7.09982 4.58306 7.33317V8.08317C4.58306 8.31653 4.58306 8.4332 4.53761 8.52233C4.49764 8.60073 4.43385 8.66448 4.3554 8.70442C4.26621 8.74984 4.14945 8.74984 3.91594 8.74984H2.33152C2.098 8.74984 1.98125 8.74984 1.89206 8.70442C1.8136 8.66448 1.74982 8.60073 1.70984 8.52233C1.6644 8.4332 1.6644 8.31653 1.6644 8.08317V7.33317Z"
                  fill="white"/>
            <path
                d="M1.56015 5.20817C0.731641 5.20817 0.0536394 4.56422 0 3.74984H10C9.94636 4.56422 9.26836 5.20817 8.43985 5.20817C7.98682 5.20817 7.57879 5.01563 7.29323 4.70798C7.00768 5.01563 6.59965 5.20817 6.14662 5.20817C5.69359 5.20817 5.28556 5.01563 5 4.70798C4.71444 5.01563 4.30641 5.20817 3.85338 5.20817C3.40035 5.20817 2.99232 5.01563 2.70677 4.70798C2.42121 5.01563 2.01318 5.20817 1.56015 5.20817Z"
                fill="white"/>
        </svg>

    ),
    Sent: (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.69951 5.30025C7.93382 5.53456 7.93382 5.91446 7.69951 6.14878L5.93491 7.91338C5.70059 8.14769 5.3207 8.14769 5.08638 7.91338C4.85207 7.67906 4.85207 7.29916 5.08638 7.06485L6.85098 5.30025C7.0853 5.06593 7.46519 5.06593 7.69951 5.30025Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.0245 2.90377C10.0245 2.90377 10.0245 2.90377 10.0245 2.90377L2.93848 5.26596C2.91959 5.27226 2.91391 5.2792 2.91128 5.28268C2.90682 5.28858 2.90167 5.2993 2.90029 5.31412C2.89892 5.32895 2.90201 5.34046 2.90533 5.34713C2.9073 5.35108 2.91162 5.35895 2.92901 5.36861L5.80192 6.96466C5.89986 7.01908 5.98061 7.09983 6.03502 7.19777L7.63102 10.0706C7.64068 10.088 7.64861 10.0924 7.65256 10.0944C7.65923 10.0977 7.67074 10.1008 7.68556 10.0994C7.70039 10.098 7.71111 10.0929 7.71701 10.0884C7.72049 10.0858 7.72742 10.0801 7.73372 10.0612L10.0959 2.97522C10.1015 2.95847 10.1 2.94978 10.0987 2.94473C10.0968 2.93732 10.0918 2.92718 10.0822 2.91752C10.0725 2.90786 10.0624 2.90289 10.055 2.90098C10.0499 2.89967 10.0412 2.89819 10.0245 2.90377ZM9.64499 1.76535C10.627 1.438 11.5617 2.37264 11.2343 3.3547L8.87214 10.4407C8.52637 11.478 7.11263 11.6087 6.58204 10.6533C6.58202 10.6533 6.58206 10.6534 6.58204 10.6533L5.06929 7.9304L2.34645 6.41771C2.34642 6.41769 2.34649 6.41773 2.34645 6.41771C1.39112 5.88712 1.52167 4.47334 2.55898 4.12755C2.55897 4.12756 2.55898 4.12755 2.55898 4.12755L9.64499 1.76535Z"
                  fill="white"/>
        </svg>

    ),
    Receive: (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.90005 2.3999H2.10005C1.93436 2.3999 1.80005 2.53422 1.80005 2.6999V3.2999C1.80005 3.46559 1.93436 3.5999 2.10005 3.5999H9.90005C10.0657 3.5999 10.2 3.46559 10.2 3.2999V2.6999C10.2 2.53422 10.0657 2.3999 9.90005 2.3999Z"
                fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.19995 2.6998C1.19995 2.20275 1.60289 1.7998 2.09995 1.7998H9.89995C10.397 1.7998 10.8 2.20275 10.8 2.6998V3.2998C10.8 3.79686 10.397 4.1998 9.89995 4.1998H2.09995C1.60289 4.1998 1.19995 3.79686 1.19995 3.2998V2.6998Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.9999 6C9.33127 6 9.5999 6.26863 9.5999 6.6V10.2C9.5999 10.5314 9.33127 10.8 8.9999 10.8C8.66853 10.8 8.3999 10.5314 8.3999 10.2V6.6C8.3999 6.26863 8.66853 6 8.9999 6Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.07564 8.27535C7.30995 8.04103 7.68985 8.04103 7.92417 8.27535L8.9999 9.35108L10.0756 8.27535C10.31 8.04103 10.6899 8.04103 10.9242 8.27535C11.1585 8.50966 11.1585 8.88956 10.9242 9.12387L9.42417 10.6239C9.18985 10.8582 8.80995 10.8582 8.57564 10.6239L7.07564 9.12387C6.84132 8.88956 6.84132 8.50966 7.07564 8.27535Z"
                  fill="white"/>
            <path
                d="M6.22745 9.9725C5.88785 9.6329 5.70005 9.1805 5.70005 8.6999C5.70005 8.2193 5.88725 7.7669 6.22745 7.4273C6.49565 7.1591 6.83345 6.9857 7.20005 6.9245V6.5999C7.20005 6.1373 7.38065 5.7191 7.66805 5.3999H1.80005V7.7999C1.80005 9.1235 2.87645 10.1999 4.20005 10.1999H6.45485L6.22745 9.9725Z"
                fill="white"/>
        </svg>

    ),

}