import './style.css';
import React, { useEffect, useState } from "react";
import request from "../../api/requests.ts"
import { GiftPurchased } from "./GiftCard.tsx";
import { tg } from "../../App.js"
import Empty from '../shared/Empty.tsx';
import {useTranslation} from "react-i18next";
import Loader from '../shared/Loader.tsx';
import Modal from '../shared/Modal.tsx';


const GiftPage = () => {
    const { t } = useTranslation();
    const [modalActive, setModalActive] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const [gifts, setGifts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchGifts = async () => {
            try {
                const userId = tg.initDataUnsafe.user.id
                const response = await request("GET", "mygifts", {'userId': userId,});
                const data = await response.data;
                setGifts(data);
            } catch (error) {
                console.error("Ошибка при загрузке подарков:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchGifts();
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="gifts">
            <header>
                <p className='first'><b>{t("gifts.1")}</b></p>
                <p className='second'>{t("gifts.2")}</p>
            </header>
            {gifts.length > 0 ? (
                <div className="content">
                    {gifts.map((gift) => (
                        <GiftPurchased
                            key={gift._id}
                            modalActive={modalActive}
                            setModalActive={setModalActive}
                            setModalContent={setModalContent}
                            {...gift}
                        />
                    ))}
                </div>
            ) : (
                <div className="no-content">
                    <Empty page="gifts" />
                </div>
            )}
            <Modal
                active={modalActive}
                setActive={setModalActive}
                props={modalContent}
                callPage={'gifts'}
            />
        </div>
    )
};

export default GiftPage;