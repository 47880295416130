import './style.css';
import React from "react";
import giftImage from '../../assets/gift.svg';
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';


const Item = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userId = props.id;
    const rank = props.rank;

    const userPhoto = `${process.env.REACT_APP_SERVER_URL}/api/user_photos/${userId}`;

    const goToProfile = () => {
        if (props.id != props.userId && props.gifts_received != 0) {
            const backPage = "leaderboard";
            navigate('/leaderboardprofile', { state: {userId, rank, backPage} });
        }
    }

    return (
        <div className="item" onClick={goToProfile}>
            <img src={userPhoto} alt={"User Photo"} className="item-img" />
            <div className="item-body">
                <div className="item-details">
                        <div className="item-name">
                            <p>{props.first_name} {props.last_name}</p>
                            {props.id == props.userId && (
                                <div className="itsme"><p>You</p></div>
                            )}
                        </div>
                    <div className="item-gifts">
                        <img className="item-icon" alt="Gift" src={giftImage}/>
                        <p>{props.gifts_received} {t("leaderboard.gifts")}</p>
                    </div>
                </div>
            </div>
            <div className="item-value">
                <div className="item-value-detail">
                    <p>#{rank}</p>
                </div>
            </div>
            <div className="separator"/>
        </div>
    )
};

export default Item;
