import './style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { tg } from "../../App.js"
import { useEffect } from "react";
import request from "../../api/requests.ts";
import { currencyWithBgIcons } from '../../assets/currency/currencyWithBg.tsx'
import { formatCount } from '../../utils/main.ts';
import { giftIcon } from '../../assets/gifts/icons.tsx';
import {useTranslation} from "react-i18next";
import {animationGiftIcons} from "../../assets/giftAnimatedIcons.tsx";
import bg from "../../assets/gifts/bgMain.svg";


const GiftBuy = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { id, name, sold_count, total_count, price, currency } = location.state;
    const navigate = useNavigate();

    useEffect(() => {
        function goBack () {
            navigate('/store');
            document.querySelector('.tabbar').style.display = 'block';
            tg.BackButton.hide();
            tg.MainButton.hide();
            tg.SecondaryButton.hide();
        }
        tg.BackButton.show();
        tg.BackButton.onClick(goBack);

        const openCryptoBotInvoice = async () => {
            try {
                const data = {
                    'price': price,
                    'currency': currency,
                    'name': name,
                }
                const response = await request("GET", "create_invoice", data);

                const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
                const hash = response.data.hash
                ws.onopen = () => {
                    const userId = tg.initDataUnsafe.user.id
                    const giftId = id
                    ws.send(JSON.stringify({ userId: userId, giftId: giftId, hash: hash }));
                };

                tg.openTelegramLink(`${response.data.miniAppPayUrl}&mode=compact`);

                ws.onmessage = (event) => {
                    const data = JSON.parse(event.data);

                    if (data.status === 'success') {
                        navigate('/giftpurchased', { state: {id, name, price, currency, hash} });
                    }
                };

                // TODO здесь нужно корректно закрывать websocket соединение
                // return () => {
                //     ws.close();
                // };

            } catch (error) {
                alert(`Ошибка при создании платежа: ${error}`);
            }
        }


        tg.MainButton.show();
        tg.MainButton.setText(t("button.buy"));
        tg.MainButton.onClick(openCryptoBotInvoice);

        return () => {
            tg.BackButton.offClick(goBack);
            tg.MainButton.offClick(openCryptoBotInvoice);
        };
    }, []);

    const gradientMap = {
        1: "var(--gradient-id-1)",
        2: "var(--gradient-id-2)",
        3: "var(--gradient-id-3)",
        4: "var(--gradient-id-4)"
    };

    return (
        <div className="GiftBuy">
            <div className="fill">
                <img className="vector" alt="Gift" src={bg}/>
                <div className="overlay-gradient" style={{ background: gradientMap[id] }}></div>
                <div className="animation">
                    {animationGiftIcons[id]}
                </div>
            </div>
            <div className="name-count">
                <p>{name}</p>
                <div><p>{formatCount(sold_count)} {t("of")} {formatCount(total_count)}</p></div>
            </div>
            <p className={"desc"}>{t("store.desc")}</p>
            <div className="giftprice">
                {currencyWithBgIcons[currency]}
                <p>{price} {currency}</p>
            </div>
        </div>
    )
};


export default GiftBuy;