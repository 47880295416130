// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab {
    height: 50px;
    position: relative;
    width: 98px;
}

.tab .animat {
    height: 26px;
    left: 36px;
    position: absolute;
    top: 4px;
    width: 26px;
}

.active .tab .label {
    color: #007AFF;
}

.active .tab svg path {
    fill: #007AFF;
}

.tab .label {
    color: #545458A6;
    font-family: "SF Pro Text";
    font-size: 10px;
    font-weight: 500;
    left: 4px;
    letter-spacing: 0.10px;
    line-height: normal;
    position: absolute;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    top: 33px;
    width: 90px;
}

.tabs {
    display: flex;
    height: 50px;
    position: relative;
    padding-top: 8px;
}

.tabbar {
    background-color: var(--my-theme-bg-menu-color);
    position: fixed;
    bottom: 0;
    z-index: 3;
    width: 100%;
}

.menu-separator {
    position: absolute;
    width: 100%;
    height: 0.33px;
    background: rgba(60, 60, 67, 0.36);
}
`, "",{"version":3,"sources":["webpack://./src/router/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,kBAAkB;IAClB,QAAQ;IACR,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,0BAA0B;IAC1B,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,kCAAkC;IAClC,8BAA8B;IAC9B,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,+CAA+C;IAC/C,eAAe;IACf,SAAS;IACT,UAAU;IACV,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,cAAc;IACd,kCAAkC;AACtC","sourcesContent":[".tab {\r\n    height: 50px;\r\n    position: relative;\r\n    width: 98px;\r\n}\r\n\r\n.tab .animat {\r\n    height: 26px;\r\n    left: 36px;\r\n    position: absolute;\r\n    top: 4px;\r\n    width: 26px;\r\n}\r\n\r\n.active .tab .label {\r\n    color: #007AFF;\r\n}\r\n\r\n.active .tab svg path {\r\n    fill: #007AFF;\r\n}\r\n\r\n.tab .label {\r\n    color: #545458A6;\r\n    font-family: \"SF Pro Text\";\r\n    font-size: 10px;\r\n    font-weight: 500;\r\n    left: 4px;\r\n    letter-spacing: 0.10px;\r\n    line-height: normal;\r\n    position: absolute;\r\n    text-align: center;\r\n    text-underline-position: from-font;\r\n    text-decoration-skip-ink: none;\r\n    top: 33px;\r\n    width: 90px;\r\n}\r\n\r\n.tabs {\r\n    display: flex;\r\n    height: 50px;\r\n    position: relative;\r\n    padding-top: 8px;\r\n}\r\n\r\n.tabbar {\r\n    background-color: var(--my-theme-bg-menu-color);\r\n    position: fixed;\r\n    bottom: 0;\r\n    z-index: 3;\r\n    width: 100%;\r\n}\r\n\r\n.menu-separator {\r\n    position: absolute;\r\n    width: 100%;\r\n    height: 0.33px;\r\n    background: rgba(60, 60, 67, 0.36);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
