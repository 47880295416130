import { useNavigate } from 'react-router-dom';
import "./style.css"
import { currencyIcons } from '../../assets/currency/currencyIcons.tsx'
import { formatCount } from '../../utils/main.ts';
import { giftIcon } from '../../assets/gifts/icons.tsx';
import { animationGiftIcons } from '../../assets/giftAnimatedIcons.tsx';
import {useTranslation} from "react-i18next";
import bg from "../../assets/gifts/bgMini.svg";


const GiftCard = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isSoldOut = props.sold_count >= props.total_count;

    const handleClick = () => {
        if (!isSoldOut) {
            navigate('/giftbuy', { state: props });
            document.querySelector('.tabbar').style.display = 'none';
        }
    };

    const gradientMap = {
        1: "var(--gradient-id-1)",
        2: "var(--gradient-id-2)",
        3: "var(--gradient-id-3)",
        4: "var(--gradient-id-4)"
    };

    return (
        <div className="GiftCardStore">
            <img className="vector" alt="Gift" src={bg}/>
            <div className="overlay-gradient" style={{background: gradientMap[props.id]}}></div>
            <p className="counter">
                {formatCount(props.sold_count)} {t("of")} {formatCount(props.total_count)}
            </p>
            <div className="gift-icon">{animationGiftIcons[props.id]}</div>
            <p className="giftname">{props.name}</p>
            <button onClick={handleClick} className={`btn ${isSoldOut ? "btn-disabled" : "btn-active"}`}
                    disabled={isSoldOut}>
                {currencyIcons[props.currency]}
                {isSoldOut ? t("store.soldout") : `${props.price} ${props.currency}`}
            </button>
        </div>
    )
}


export {GiftCard};
