

export const currencyIcons = {
    TON: (
        <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M27.3438 10.9734C27.8586 10.9734 28.3734 11.0497 28.9126 11.2998C29.5589 11.6018 29.8999 12.076 30.1403 12.4267C30.4546 12.9416 30.6204 13.5334 30.6194 14.1366C30.6216 14.7306 30.4718 15.3153 30.1841 15.8351L20.9762 31.6495C20.7737 31.999 20.3998 32.2136 19.9959 32.2123C19.5919 32.211 19.2195 31.9939 19.0193 31.643L9.9819 15.8611L9.97378 15.8481C9.76753 15.5071 9.44761 14.981 9.39077 14.3006C9.28616 13.0194 9.9878 11.8078 11.1512 11.2608C11.7618 10.9766 12.3805 10.9734 12.7703 10.9734H27.3438ZM18.8699 13.2338H12.7703C12.3691 13.2338 12.2149 13.2581 12.1077 13.3085C11.7976 13.452 11.6116 13.7753 11.6432 14.1155C11.6513 14.2129 11.6903 14.3217 11.9209 14.7017L11.9355 14.7261L18.8699 26.8349V13.2338ZM21.1305 26.895L28.2272 14.7066C28.3167 14.53 28.3624 14.3345 28.3604 14.1366C28.3604 13.9628 28.3247 13.8118 28.2435 13.6559C28.1902 13.5744 28.1305 13.4973 28.0648 13.4254C28.0347 13.3925 27.9983 13.366 27.9577 13.3474C27.7647 13.2622 27.5545 13.2233 27.3438 13.2338H21.1305V26.895Z"
                  fill="white"/>
        </svg>
    ),
    USDT: (
        <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M20.0232 21.4351C23.2209 21.4351 25.8933 20.8992 26.5465 20.1832C25.9919 19.576 23.986 19.0977 21.4386 18.9668V20.4795C20.9826 20.503 20.5085 20.5145 20.0227 20.5145C19.5369 20.5145 19.0628 20.503 18.6058 20.4795V18.9668C16.0594 19.0977 14.0526 19.576 13.498 20.1832C14.1521 20.8992 16.825 21.4351 20.0227 21.4351H20.0232ZM25.7046 14.3151V16.3982H21.4386V17.8426C24.4351 17.997 26.6836 18.6319 26.7003 19.3917V20.9757C26.6836 21.7355 24.4351 22.369 21.4386 22.5238V26.0689H18.6063V22.5238C15.6098 22.3695 13.3623 21.7355 13.3455 20.9757V19.3917C13.3623 18.6319 15.6098 17.997 18.6063 17.8426V16.3982H14.3403V14.3151H25.7051H25.7046ZM12.6627 11H27.6384C27.9963 11 28.3259 11.1866 28.5044 11.4898L32.8671 18.9152C33.093 19.3004 33.0261 19.7865 32.7034 20.0985L20.6944 31.7177C20.3049 32.0941 19.6792 32.0941 19.2906 31.7177L7.2964 20.1141C6.96682 19.7944 6.90453 19.2944 7.14765 18.9074L11.8115 11.4667C11.9933 11.1774 12.3159 11.0005 12.6631 11.0005L12.6627 11Z"
                  fill="white"/>
        </svg>
    ),
    ETH: (
        <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9979 6.90259V16.5857L28.1805 20.2428L19.9979 6.90259Z" fill="white" fill-opacity="0.602"/>
            <path d="M19.9979 6.90259L11.8142 20.2428L19.9979 16.5857V6.90259Z" fill="white"/>
            <path d="M19.9979 26.5178V33.0973L28.1859 21.7668L19.9979 26.5178Z" fill="white" fill-opacity="0.602"/>
            <path d="M19.9979 33.0973V26.5167L11.8142 21.7668L19.9979 33.0973Z" fill="white"/>
            <path d="M19.9979 24.9949L28.1805 20.2428L19.9979 16.5879V24.9949Z" fill="white" fill-opacity="0.2"/>
            <path d="M11.8142 20.2428L19.9979 24.9949V16.5879L11.8142 20.2428Z" fill="white" fill-opacity="0.602"/>
        </svg>
    ),

}