import Lottie from "lottie-react";
import animationLeaderboard from "./animations/tab-leaderboard.json";
import animationGifts from "./animations/tab-gifts.json";
import animationStore from "./animations/tab-store.json";
import animationProfile from "./animations/tab-profile.json";


const animations = {
    Store: animationStore,
    Gifts: animationGifts,
    Leaderboard: animationLeaderboard,
    Profile: animationProfile,
};


export const animationIcons = (lottieRef) =>
    Object.entries(animations).reduce((acc, [key, animationData]) => {
        acc[key] = (
            <Lottie
                lottieRef={lottieRef}
                animationData={animationData}
                loop={false}
                autoplay={false}
            />
        );
        return acc;
    }, {});

