import './App.css'
import React, { useEffect } from "react"
import MainRouter from"./router/Routes.tsx"
import { useNavigate } from "react-router-dom"
import { checkStartParam, addUser } from './services/main.ts'
import { useTheme, useLanguage } from "./hooks/use-theme.ts";


const tg = window.Telegram.WebApp;

function App() {
    const navigate = useNavigate();
    const { theme, setTheme } = useTheme();
    const { lang, setLanguage } = useLanguage();

    useEffect(() => {
        const adjustBottomPadding = () => {
            const tabbar = document.querySelector('.tabbar');

            if (window.innerHeight > tg.viewportStableHeight) {
                tabbar.style.height = `92px`;
            } else {
                tabbar.style.height = "58px";
            }
        };

        adjustBottomPadding();
        window.addEventListener('load', adjustBottomPadding);

        return () => {
            window.removeEventListener('load', adjustBottomPadding);
        };
    }, []);

    useEffect(() => {
        tg.ready();
        tg.expand();
        tg.disableVerticalSwipes();
        (async () => {
            await addUser();
            await checkStartParam(navigate);
        })();
    }, [])

    return (
        <div className="container">
            <MainRouter/>
        </div>
    )
}

export default App
export {tg}

